<template>
  <fieldset class="border rounded my-2 ml-4 px-2 pt-2">
    <div class="flex items-center mx-1 mb-2">
      文件紀錄
    </div>
    <Grid ref="recordGrid" v-bind="gridOptions" @edit="onGridEdit">
      <template #column-files="{ row }">
        <div v-for="item, index in row.Files" :key="'record-file-' + index" class="p-1"><a class="cursor-pointer hover:text-blue-600"
            @click.prevent="download(item.Id)">{{ item.Name }}</a></div>
      </template>
      <template #modal="{ row }">
        <vxe-form class="ml-3" :data="row">
          <template v-if="!row.ReportChecklist && !row.ProofChecklist && !row.ReviewChecklist">
            <vxe-form-item title="原標題" field="Title" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.Title" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="新標題" field="NewTitle" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.NewTitle" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="變更原因" field="TitleReason" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.TitleReason" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="原發布日期" field="PublishedDate" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.PublishedDate" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="新發布日期" field="NewPublishedDate" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.NewPublishedDate" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="變更原因" field="PublishedDateReason" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.PublishedDateReason" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="原撰稿人" field="ReportUser.Name" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input :model-value="data.ReportUser?.Name" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="新撰稿人" field="NewReportUser.Name" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input :model-value="data.NewReportUser?.Name" readonly></vxe-input>
              </template>
            </vxe-form-item>
            <vxe-form-item title="變更原因" field="ReportUserReason" :item-render="{}" span="8">
              <template #default="{ data }">
                <vxe-input v-model="data.ReportUserReason" readonly></vxe-input>
              </template>
            </vxe-form-item>
          </template>
          <vxe-form-item v-if="row.ReportChecklist || row.ProofChecklist || row.ReviewChecklist" :item-render="{}"
            span="24">
            <template #default="{ data }">
              <table class="w-full table border" v-if="data.ReportChecklist">
                <thead>
                  <tr class="bg-gray-50 border-b">
                    <th class="p-2 border-r text-sm font-thin" style="width: 150px">
                      <div class="flex items-center justify-center">類型</div>
                    </th>
                    <th class="p-2 border-r text-sm font-thin">
                      <div class="flex items-center justify-center">檢核項目</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReportChecklist.IsTemplate" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      版底
                    </td>
                    <td class="p-2 border-r">
                      <div>● 封面，字型，字體大小。</div>
                      <div>● 標題層次，內文段落。</div>
                      <div>● 圖、圖名稱、圖資料來源。</div>
                      <div>● 表、表名稱、表資料來源。</div>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReportChecklist.IsAbstract" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      評析摘要
                    </td>
                    <td class="p-2 border-r">● 摘要為研究目的及發現，非緣起及前言。</td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReportChecklist.IsContent" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      評析內容
                    </td>
                    <td class="p-2 border-r">
                      <div>● 評析語意流暢度及邏輯性。</div>
                      <div>● 錯別字檢視校正。</div>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReportChecklist.IsChart" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      圖表內容
                    </td>
                    <td class="p-2 border-r">
                      <div>● 圖表內容是否清晰。</div>
                      <div>● 基本單位符合國際慣用上下標。</div>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReportChecklist.IsWord" :checked-value="true" :unchecked-value="false"
                        disabled></vxe-checkbox>
                      字數是否符合
                    </td>
                    <td class="p-2 border-r">● 字數是否達2,000字+1圖/表（Word檔）。</td>
                  </tr>
                </tbody>
              </table>
              <table class="w-full table border" v-if="data.ProofChecklist">
                <thead>
                  <tr class="bg-gray-50 border-b">
                    <th class="p-2 border-r text-sm font-thin" style="width: 150px">
                      <div class="flex items-center justify-center">類型</div>
                    </th>
                    <th class="p-2 border-r text-sm font-thin" style="width: 300px">
                      <div class="flex items-center justify-center">檢核項目</div>
                    </th>
                    <th class="p-2 border-r text-sm font-thin">
                      <div class="flex items-center justify-center">校稿建議</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ProofChecklist.IsTemplate" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      版底
                    </td>
                    <td class="p-2 border-r">
                      <div>● 封面，字型，字體大小。</div>
                      <div>● 標題層次，內文段落。</div>
                      <div>● 圖、圖名稱、圖資料來源。</div>
                      <div>● 表、表名稱、表資料來源。</div>
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ProofChecklist.IsTemplateText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ProofChecklist.IsAbstract" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      評析摘要
                    </td>
                    <td class="p-2 border-r">● 摘要為研究目的及發現，非緣起及前言。</td>
                    <td>
                      <vxe-textarea v-model="data.ProofChecklist.IsAbstractText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ProofChecklist.IsContent" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      評析內容
                    </td>
                    <td class="p-2 border-r">
                      <div>● 評析語意流暢度及邏輯性。</div>
                      <div>● 錯別字檢視校正。</div>
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ProofChecklist.IsContentText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ProofChecklist.IsChart" :checked-value="true" :unchecked-value="false"
                        disabled></vxe-checkbox>
                      圖表內容
                    </td>
                    <td class="p-2 border-r">
                      <div>● 圖表內容是否清晰。</div>
                      <div>● 基本單位符合國際慣用上下標。</div>
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ProofChecklist.IsChartText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ProofChecklist.IsWord" :checked-value="true" :unchecked-value="false"
                        disabled></vxe-checkbox>
                      字數是否符合
                    </td>
                    <td class="p-2 border-r">● 字數是否達2,000字+1圖/表（Word檔）。</td>
                    <td>
                      <vxe-textarea v-model="data.ProofChecklist.IsWordText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
              <table class="w-full border table" v-if="data.ReviewChecklist">
                <thead>
                  <tr class="bg-gray-50 border-b">
                    <th class="p-2 border-r text-sm font-thin" style="width: 380px">
                      <div class="flex items-center justify-center">類型</div>
                    </th>
                    <th class="p-2 border-r text-sm font-thin">
                      <div class="flex items-center justify-center">審稿建議</div>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReviewChecklist.IsIssue" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      議題的重要性、即時性
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ReviewChecklist.IsIssueText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReviewChecklist.IsAbstract" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      『摘要』是否包含研究目的及發現
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ReviewChecklist.IsAbstractText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReviewChecklist.IsContent" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      內容是否切合主題
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ReviewChecklist.IsContentText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReviewChecklist.IsArticle" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      文章內容及架構的完整性、流暢性、邏輯性
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ReviewChecklist.IsArticleText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReviewChecklist.IsChart" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      圖表數據正確性及參考價值
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ReviewChecklist.IsChartText" :autosize="{ minRows: 4, maxRows: 6 }"
                        readonly></vxe-textarea>
                    </td>
                  </tr>
                  <tr class="border-b text-sm">
                    <td class="p-2 border-r">
                      <vxe-checkbox v-model="data.ReviewChecklist.IsProfessional" :checked-value="true"
                        :unchecked-value="false" disabled></vxe-checkbox>
                      是否具專業觀點與分析深度、結論與建議是否具價值性
                    </td>
                    <td>
                      <vxe-textarea v-model="data.ReviewChecklist.IsProfessionalText"
                        :autosize="{ minRows: 4, maxRows: 6 }" readonly></vxe-textarea>
                    </td>
                  </tr>
                </tbody>
              </table>
            </template>
          </vxe-form-item>
          <vxe-form-item title="意見" field="Comment" :item-render="{}" span="24">
            <template #default="{ data }">
              <vxe-textarea v-model="data.Comment" readonly></vxe-textarea>
            </template>
          </vxe-form-item>
          <vxe-form-item :item-render="{}" span="24">
            <template #default>
              <FileUploadTable ref="fileGrid" v-bind="fileGridOptions" />
            </template>
          </vxe-form-item>
        </vxe-form>
      </template>
    </Grid>
  </fieldset>
</template>
<script lang="ts">
import CloudFun, { Condition, defineComponent, Operator, ref, Sorting, PropType, SortOrder } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue';
import { ceil } from 'xe-utils';
export default defineComponent({
  components: {
    Grid
  },
  props: {
    documentId: Number,
  },
  setup(props) {
    const model = CloudFun.current?.model

    const recordGrid = ref<any>();
    const files = ref<any[]>([]);
    const gridOptions: GridOptions = {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      autoResize: true,
      columns: [
        // { field: 'Title', title: '標題', showOverflow: true, sortable: true, resizable: false },
        // { field: 'NewTitle', title: '新標題', showOverflow: true, sortable: true, resizable: false },
        // { field: 'PublishedDate', title: '發布日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
        // { field: 'NewPublishedDate', title: '新發布日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : "" },
        { field: 'CreatedTime', title: '紀錄時間', showOverflow: true, sortable: false, resizable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm') },
        // { field: 'BeforeStatus', title: '狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => { const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue); return item ? item.Name : ""; } },
        { field: 'AfterStatus', title: '狀態', showHeaderOverflow: true, showOverflow: false, sortable: false, formatter: ({ cellValue }) => { const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue); return item ? item.Name : ""; } },
        { field: 'Operator.Name', title: '送件者', showOverflow: true, sortable: false, resizable: true },
        { field: 'CheckListContent', title: '意見紀錄', showOverflow: false, sortable: false, resizable: true, type: 'html', minWidth: '360' },
        { title: "檔案", slots: { default: "column-files" } }
        // { field: 'Comment', title: '意見', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: {
        query: model ? (params) => {
          if (!props.documentId) return Promise.resolve([]);
          params.condition = new Condition("DocumentId", Operator.Equal, props.documentId).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return new Promise<any>(resolve => {
            model.dispatch("documentRecord/query", params).then(async resp => {
              files.value = await model?.dispatch('files/query', {
                condition: new Condition("DocumentRecordId", Operator.In, resp.data.map((e: { Id: any; }) => e.Id))
                  .and(
                    new Condition("Type", Operator.Equal, 3)
                      .or("Type", Operator.Equal, 4)
                  )
              });
              resp.data.forEach((e: any) => {
                e.Files = files.value.filter(f => f.DocumentRecordId === e.Id);
              })
              resolve(resp);
            });
          });
        } : undefined,
      }
    }

    // const formOptions: VxeFormProps = {
    //   titleWidth: 80,
    //   titleAlign: 'right',
    //   items: [
    //     { field: 'Title', title: '原標題', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'NewTitle', title: '新標題', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'TitleReason', title: '變更原因', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'PublishedDate', title: '原發布日期', span: 8, titleWidth: 100, itemRender: { name: '$input', props: { type: 'date', readonly: true } } },
    //     { field: 'NewPublishedDate', title: '新發布日期', span: 8, titleWidth: 100, itemRender: { name: '$input', props: { type: 'date', readonly: true } } },
    //     { field: 'PublishedDateReason', title: '變更原因', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'ReportUser.Name', title: '原撰稿人', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'NewReportUser.Name', title: '新撰稿人', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'ReportUserReason', title: '變更原因', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     // {
    //     //   field: 'BeforeStatusName',
    //     //   title: '原狀態',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { readonly: true } }
    //     //   // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
    //     // },
    //     // {
    //     //   field: 'AfterStatusName',
    //     //   title: '新狀態',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { readonly: true } }
    //     //   // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
    //     // },
    //     // {
    //     //   field: 'Operator.Name',
    //     //   title: '操作人員',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { readonly: true } }
    //     //   // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
    //     // },
    //     { span: 24, slots: { default: 'check-list' } },
    //     { field: 'Comment', title: '意見', span: 24, itemRender: { name: '$textarea', props: { readonly: true } } },
    //     { span: 24, slots: { default: 'files' } }
    //   ]
    // }

    const fileGridOptions = {
      canUpload: false,
      canDelete: false,
      promises: {
        query() {
          return files.value.filter(e => e.DocumentRecordId === recordGrid.value.editingRow?.Id);
          // return recordGrid.value.editingRow
          //   ? model?.dispatch('files/query', {
          //     condition: new Condition("DocumentRecordId", Operator.Equal, recordGrid.value.editingRow?.Id)
          //       .and(
          //         new Condition("Type", Operator.Equal, 3)
          //           .or("Type", Operator.Equal, 4)
          //       )
          //   }) : undefined;
        }
      }
    };

    watch(() => props.documentId, (value) => {
      recordGrid.value.refresh();
    })

    return {
      recordGrid,
      gridOptions,
      // formOptions,
      fileGridOptions
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      Object.assign(row, entity)
      const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    download(id: number) {
      window.open(
        `${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`
      );
    }
  }
});
</script>
