
import CloudFun, { Condition, defineComponent, Operator, ref, Sorting, PropType, SortOrder } from '@cloudfun/core'
import Grid, { GridOptions } from '@/cloudfun/components/Grid.vue'
import { VxeFormProps } from 'vxe-table'
import formatDate from 'xe-utils/toDateString'
import { watch } from 'vue';
import { ceil } from 'xe-utils';
export default defineComponent({
  components: {
    Grid
  },
  props: {
    documentId: Number,
  },
  setup(props) {
    const model = CloudFun.current?.model

    const recordGrid = ref<any>();
    const files = ref<any[]>([]);
    const gridOptions: GridOptions = {
      canCreate: false,
      canUpdate: false,
      canDelete: false,
      multiselect: false,
      autoResize: true,
      columns: [
        // { field: 'Title', title: '標題', showOverflow: true, sortable: true, resizable: false },
        // { field: 'NewTitle', title: '新標題', showOverflow: true, sortable: true, resizable: false },
        // { field: 'PublishedDate', title: '發布日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd') },
        // { field: 'NewPublishedDate', title: '新發布日期', showOverflow: true, sortable: true, resizable: false, formatter: ({ cellValue }) => cellValue ? formatDate(new Date(cellValue), 'yyyy/MM/dd') : "" },
        { field: 'CreatedTime', title: '紀錄時間', showOverflow: true, sortable: false, resizable: true, formatter: ({ cellValue }) => formatDate(new Date(cellValue), 'yyyy/MM/dd HH:mm') },
        // { field: 'BeforeStatus', title: '狀態', showHeaderOverflow: true, showOverflow: true, sortable: true, formatter: ({ cellValue }) => { const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue); return item ? item.Name : ""; } },
        { field: 'AfterStatus', title: '狀態', showHeaderOverflow: true, showOverflow: false, sortable: false, formatter: ({ cellValue }) => { const item = model?.enums && Object.values(model?.enums.DocumentStatus).find((e) => e.Value === cellValue); return item ? item.Name : ""; } },
        { field: 'Operator.Name', title: '送件者', showOverflow: true, sortable: false, resizable: true },
        { field: 'CheckListContent', title: '意見紀錄', showOverflow: false, sortable: false, resizable: true, type: 'html', minWidth: '360' },
        { title: "檔案", slots: { default: "column-files" } }
        // { field: 'Comment', title: '意見', showOverflow: true, sortable: true, resizable: false },
      ],
      promises: {
        query: model ? (params) => {
          if (!props.documentId) return Promise.resolve([]);
          params.condition = new Condition("DocumentId", Operator.Equal, props.documentId).and(params.condition!);
          params.sortings!.push({ column: 'CreatedTime', order: 1 });
          return new Promise<any>(resolve => {
            model.dispatch("documentRecord/query", params).then(async resp => {
              files.value = await model?.dispatch('files/query', {
                condition: new Condition("DocumentRecordId", Operator.In, resp.data.map((e: { Id: any; }) => e.Id))
                  .and(
                    new Condition("Type", Operator.Equal, 3)
                      .or("Type", Operator.Equal, 4)
                  )
              });
              resp.data.forEach((e: any) => {
                e.Files = files.value.filter(f => f.DocumentRecordId === e.Id);
              })
              resolve(resp);
            });
          });
        } : undefined,
      }
    }

    // const formOptions: VxeFormProps = {
    //   titleWidth: 80,
    //   titleAlign: 'right',
    //   items: [
    //     { field: 'Title', title: '原標題', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'NewTitle', title: '新標題', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'TitleReason', title: '變更原因', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'PublishedDate', title: '原發布日期', span: 8, titleWidth: 100, itemRender: { name: '$input', props: { type: 'date', readonly: true } } },
    //     { field: 'NewPublishedDate', title: '新發布日期', span: 8, titleWidth: 100, itemRender: { name: '$input', props: { type: 'date', readonly: true } } },
    //     { field: 'PublishedDateReason', title: '變更原因', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'ReportUser.Name', title: '原撰稿人', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'NewReportUser.Name', title: '新撰稿人', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     { field: 'ReportUserReason', title: '變更原因', span: 8, itemRender: { name: '$input', props: { readonly: true } } },
    //     // {
    //     //   field: 'BeforeStatusName',
    //     //   title: '原狀態',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { readonly: true } }
    //     //   // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
    //     // },
    //     // {
    //     //   field: 'AfterStatusName',
    //     //   title: '新狀態',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { readonly: true } }
    //     //   // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
    //     // },
    //     // {
    //     //   field: 'Operator.Name',
    //     //   title: '操作人員',
    //     //   span: 12,
    //     //   itemRender: { name: '$input', props: { readonly: true } }
    //     //   // itemRender: { name: "$select", for, options: model ? Object.values(model.enums.TopicType).map(e => { return { label: e.Name, value: e.Value } }) : [], attrs: { readonly: true } }
    //     // },
    //     { span: 24, slots: { default: 'check-list' } },
    //     { field: 'Comment', title: '意見', span: 24, itemRender: { name: '$textarea', props: { readonly: true } } },
    //     { span: 24, slots: { default: 'files' } }
    //   ]
    // }

    const fileGridOptions = {
      canUpload: false,
      canDelete: false,
      promises: {
        query() {
          return files.value.filter(e => e.DocumentRecordId === recordGrid.value.editingRow?.Id);
          // return recordGrid.value.editingRow
          //   ? model?.dispatch('files/query', {
          //     condition: new Condition("DocumentRecordId", Operator.Equal, recordGrid.value.editingRow?.Id)
          //       .and(
          //         new Condition("Type", Operator.Equal, 3)
          //           .or("Type", Operator.Equal, 4)
          //       )
          //   }) : undefined;
        }
      }
    };

    watch(() => props.documentId, (value) => {
      recordGrid.value.refresh();
    })

    return {
      recordGrid,
      gridOptions,
      // formOptions,
      fileGridOptions
    }
  },
  methods: {
    refresh() {
      (this.$refs.grid as any).refresh();
    },
    async onGridEdit(row: any, callback: any) {
      const entity = row.Id ? await this.$model.dispatch('documentRecord/find', row.Id) : undefined
      Object.assign(row, entity)
      const statuses = (Object.values(this.$model.enums.DocumentStatus) as any[]);
      row.BeforeStatusName = statuses.find(e => e.Value === row.BeforeStatus).Name;
      row.AfterStatusName = statuses.find(e => e.Value === row.AfterStatus).Name;
      //   if (entity) {
      //     entity.Xml.Time = new Date(entity.Xml.Time)
      //   }
      callback()
    },
    download(id: number) {
      window.open(
        `${process.env.VUE_APP_BACKEND_URL}/api/files/download/${id}`
      );
    }
  }
});
